import {
  format as dateFNSFormatDate,
  differenceInMinutes,
  lastDayOfMonth,
} from 'date-fns'
import { DATE_FORMAT } from './constants'
import { cache } from '@lib/graphql/cache'
import { GET_USER_ACCOUNT } from '@lib/graphql/queries/Organization'
import { TFunction } from '@locales/useTranslations'
import { DEFAULT_LOCALE, EN_LOCALE } from './i18n'
import isToday from 'date-fns/isToday'
import { Timestamp } from '@lib/graphql/__generated__/graphql'

export interface TimeStamp {
  nanos?: number
  seconds: number
}

export const USER_DATETIMES = () => [
  {
    dateFormat: 'd MMM yyyy',
    datetimeFormat: 'd MMM yyyy, HH:mm',
    label: `d mmm yyyy (e.g. ${dateFNSFormatDate(new Date(), 'd MMM yyyy')})`,
  },
  {
    dateFormat: 'MMM d, yyyy',
    datetimeFormat: 'MMM d, yyyy, HH:mm',
    label: `mmm d, yyyy (e.g. ${dateFNSFormatDate(new Date(), 'MMM d, yyyy')})`,
  },
  {
    dateFormat: 'd.M.yyyy',
    datetimeFormat: 'd.M.yyyy, HH:mm',
    label: `d.m.yyyy (e.g. ${dateFNSFormatDate(new Date(), 'd.M.yyyy')})`,
  },
  {
    dateFormat: 'M/d/yyyy',
    datetimeFormat: 'M/d/yyyy, HH:mm',
    label: `m/d/yyyy (e.g. ${dateFNSFormatDate(new Date(), 'M/d/yyyy')})`,
  },
]

export const dateToUTCSeconds = (date: Date) =>
  Math.round(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    ) / 1000,
  )

export const dateToSeconds = (date: Date) => Math.round(date.getTime() / 1000)

export const getTimeStamp: (date: Date, utc?: boolean) => TimeStamp = (
  date,
  utc,
) => ({
  seconds: utc ? dateToUTCSeconds(date) : dateToSeconds(date),
})

export const createTimeStamp = (values: {
  seconds: number
  nanos?: number
}) => ({
  seconds: values.seconds,
  nanos: values.nanos,
})

export const getDateFromTimeStamp = (
  timeStamp: TimeStamp | Timestamp,
  utc?: boolean,
) => {
  if (utc) {
    return getDateFromUTCTimeStamp(timeStamp)
  }

  return new Date(timeStamp.seconds * 1000)
}

export const getDateFromUTCTimeStamp = (timeStamp: TimeStamp | Timestamp) => {
  const d = new Date(timeStamp?.seconds * 1000)
  const utcDate = new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
  )

  return utcDate
}

export const getStartOfDay = (date: string | number | Date) => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)

  return d
}

export const getStartOfMonth = (date: string | number | Date) => {
  const d = new Date(date)
  d.setDate(1)
  d.setHours(0, 0, 0, 0)

  return d
}

export const getEndOfMonth = (date: string | number | Date) => {
  const d = lastDayOfMonth(new Date(date))
  d.setHours(23, 59, 59, 59)

  return d
}

export const getEndOfDay = (date: string | number | Date) => {
  const d = new Date(date)
  d.setHours(23, 59, 59, 59)

  return d
}

export const getStartOfYear = (date: string | number | Date) => {
  const d = new Date(date)
  d.setMonth(0, 1)
  d.setHours(0, 0, 0, 0)

  return d
}

export const getEndOfYear = (date: string | number | Date) => {
  const d = new Date(date)
  d.setMonth(11, 31)
  d.setHours(23, 59, 59, 59)

  return d
}

export const getDaysDifference = (a: Date, b: Date) => {
  return Math.round((a.getTime() - b.getTime()) / (1000 * 60 * 60 * 24))
}

export const isValidDate = (timeStamp: TimeStamp | Timestamp | undefined) =>
  timeStamp?.seconds > 0

export const formatDate = (
  timeStamp: TimeStamp | Timestamp | undefined,
  withTime?: boolean,
) => {
  if (!isValidDate(timeStamp)) return null

  const DATETIMES = USER_DATETIMES()

  const getFullDateFormat = (value: string) =>
    DATETIMES.find((d) => d.dateFormat === value)

  const data = cache.readQuery({
    query: GET_USER_ACCOUNT,
  })

  const dateFormat =
    (data as any)?.getCurrentUserAccount?.datePreferences ?? DATE_FORMAT

  let fullDateFormat = getFullDateFormat(dateFormat)

  if (!fullDateFormat) {
    fullDateFormat = DATETIMES[0]
  }

  return dateFNSFormatDate(
    getDateFromTimeStamp(timeStamp, !withTime),
    withTime ? fullDateFormat.datetimeFormat : fullDateFormat.dateFormat,
  )
}

export const formatDateRelative = ({
  date,
  withTime,
  t,
}: {
  date: TimeStamp
  withTime?: boolean
  t: TFunction
}) => {
  const notificationDate = getDateFromTimeStamp(date)
  const diffMinutes = differenceInMinutes(new Date(), notificationDate)

  const data = cache.readQuery({
    query: GET_USER_ACCOUNT,
  })

  const lang = (data as any)?.getCurrentUserAccount?.lang ?? DEFAULT_LOCALE

  if (diffMinutes <= 0) {
    return t('expressions.justNow')
  }

  if (diffMinutes < 60) {
    return `${diffMinutes} ${t(
      `expressions.${diffMinutes > 1 ? 'minutes' : 'minute'}`,
    )} ${lang === EN_LOCALE ? 'ago' : ''}`
  }

  if (isToday(notificationDate)) {
    return `${t('expressions.today')}, ${dateFNSFormatDate(
      getDateFromTimeStamp(date),
      'HH:mm',
    )}`
  }

  return formatDate(date, withTime)
}

export const getBrowserTimezone = () =>
  Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Europe/Sofia'
