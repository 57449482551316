import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { UPDATE_GOAL_DETAILS } from '@lib/graphql/mutations/Goals'
import { Goal } from '@lib/types/goals'
import { getListIds } from '@utils/common'

export const serialize = ({
  owner,
  assignee,
  followers,
  delay,
  labels,
  projects,
  tasks,
  comments,
  boards,
  commentsState,
  createdAt,
  createdBy,
  userAccess,
  goalTree,
  rootId,
  parentId,
  resources,
  resourcesForGoalTree,
  ...rest
}: Goal) => ({
  ownerId: owner?.id,
  assigneeId: assignee?.id ?? null,
  labelIds: labels ? getListIds(labels) : null,
  projectIds: projects ? getListIds(projects) : null,
  followerIds: followers ? getListIds(followers) : null,
  ...rest,
})

const useUpdateGoalDetails = () => {
  const [update, result] = useMutation(UPDATE_GOAL_DETAILS)

  const updateGoalDetails = (
    goal,
    updateOptions?: MutationFunctionOptions<
      any,
      OperationVariables,
      DefaultContext,
      ApolloCache<any>
    >,
  ) =>
    update({
      variables: {
        input: serialize(goal),
      } as any,
      ...updateOptions,
    })

  return [updateGoalDetails, result] as [typeof updateGoalDetails, any]
}

export default useUpdateGoalDetails
