import React from 'react'
import path from 'ramda/src/path'
import {
  Notification,
  NotificationValueGoalDeleted,
  NotificationValueTaskDeleted,
} from '@lib/types/notification'
import { TFunction } from '@locales/useTranslations'
import { formatDate, isValidDate, TimeStamp } from './Date'
import { isSubTask } from './Task'
import { ellipsize } from './common'

const parseText = (value: string | undefined, maxTextLength = 50) =>
  value ? ellipsize(maxTextLength)(value) : '<missing>'

const getActivityDeadlineTextKey = (action: string) => {
  if (action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED) {
    return 'commentLogs.deadLineOfMilestonePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED) {
    return 'commentLogs.deadLineOfMilestoneKA'
  }

  return 'commentLogs.deadLineOfMilestone'
}

const getActivityReminderTextKey = (action: string) => {
  if (action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED) {
    return 'commentLogs.notifyMeDateOfMilestonePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED) {
    return 'commentLogs.notifyMeDateOfMilestoneKA'
  }

  return 'commentLogs.notifyMeDateOfMilestone'
}

const getActivityExecDateTextKey = (action: string) => {
  if (
    action === NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED
  ) {
    return 'commentLogs.milestoneExecDatePP'
  }
  if (action === NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED) {
    return 'commentLogs.milestoneExecDateKA'
  }

  return 'commentLogs.milestoneExecDate'
}

export const NOTIFICATION_TYPES = {
  GOAL: 'Goal',
  TASK: 'Task',
  PROJECT: 'Project',
  MILESTONE: 'Milestone',
  COMMENT: 'Comment',
  COMMENT_REPLY: 'CommentReply',
  COMMENT_REMINDER: 'CommentReminder',
  DEADLINE_REQUEST: 'DeadlineRequest',
}

export const NOTIFICATION_ACTIONS = {
  CANCELED_TASK: 'canceledTask',
  CANCELED_GOAL: 'canceledGoal',
  DEADLINE_CHANGED: 'deadlineChanged',
  PROJECT_DEADLINE_CHANGED: 'projectDeadlineChanged',
  MILESTONE_DEADLINE_CHANGED: 'milestoneDeadlineChanged',
  GOAL_DEADLINE_CHANGED: 'goalDeadlineChanged',
  ASSIGNEE_CHANGED: 'assigneeChanged',
  OWNER_CHANGED: 'ownerChanged',
  NEW_COMMENT: 'newComment',
  THREAD_NEW_COMMENT: 'threadNewComment',
  NEW_TASK: 'newTask',
  NEW_GOAL: 'newGoal',
  INPROGRESS_TASK: 'inprogressTask',
  INPROGRESS_GOAL: 'inprogressGoal',
  DONE_TASK: 'doneTask',
  DONE_GOAL: 'doneGoal',
  DELETED_TASK: 'deletedTask',
  DELETED_GOAL: 'deletedGoal',
  COMMENT_REMINDER: 'commentReminder',
  COMMENT_LIKE: 'commentLike',
  THREAD_COMMENT_REMINDER: 'threadCommentReminder',
  PENDING_DEADLINE_CHANGE_REQUEST: 'pendingDeadlineChangeRequest',
  ACCEPTED_DEADLINE_CHANGE_REQUEST: 'acceptedDeadlineChangeRequest',
  REJECTED_DEADLINE_CHANGE_REQUEST: 'rejectedDeadlineChangeRequest',
  CUSTOM_DEADLINE_CHANGE_REQUEST: 'customDeadlineChangeRequest',
  PRODUCTION_PLANNING_REMINDER_CHANGED: 'milestoneReminderChangedPP',
  PRODUCTION_PLANNING_DEADLINE_CHANGED: 'milestoneDeadlineChangedPP',
  PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED: 'milestoneExecutionDateChangedPP',
  KEY_ACTIVITIES_REMINDER_CHANGED: 'milestoneReminderChangedKA',
  KEY_ACTIVITIES_EXECUTION_DATE_CHANGED: 'milestoneExecutionDateChangedKA',
  KEY_ACTIVITIES_DEADLINE_CHANGED: 'milestoneDeadlineChangedKA',
}

export const NOTIFICATIONS_ICONS = {
  [NOTIFICATION_ACTIONS.NEW_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-sticky-note text-oxford-gray-400',
  },
  [NOTIFICATION_ACTIONS.DONE_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-jade-50 rounded-full',
    iconClassName: 'far fa-check text-jade-500',
  },
  [NOTIFICATION_ACTIONS.DELETED_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-trash-alt text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CANCELED_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-ban text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.NEW_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-sticky-note text-oxford-gray-400',
  },
  [NOTIFICATION_ACTIONS.DONE_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-jade-50 rounded-full',
    iconClassName: 'far fa-check text-jade-500',
  },
  [NOTIFICATION_ACTIONS.DELETED_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-trash-alt text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CANCELED_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-watermelon-50 rounded-full',
    iconClassName: 'far fa-ban text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.NEW_COMMENT]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-alt-dots text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-alt-dots text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.COMMENT_REMINDER]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-alarm-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.THREAD_COMMENT_REMINDER]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-alarm-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.COMMENT_LIKE]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-up text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.OWNER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-repeat text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PROJECT_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.MILESTONE_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.GOAL_DEADLINE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-clock text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PENDING_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-comment-question text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.ACCEPTED_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-up text-jade-500',
  },
  [NOTIFICATION_ACTIONS.REJECTED_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-thumbs-down text-watermelon-800',
  },
  [NOTIFICATION_ACTIONS.CUSTOM_DEADLINE_CHANGE_REQUEST]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-arrows-repeat text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.INPROGRESS_TASK]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.INPROGRESS_GOAL]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-exchange text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-table text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-bell text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-table text-cornflower-blue-400',
  },
  [NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED]: {
    containerClassName:
      'w-5 h-5 flex items-center justify-center bg-oxford-gray-100 rounded-full',
    iconClassName: 'far fa-bell text-cornflower-blue-400',
  },
}

export const getNotificationIcon = (type: string, action: string) => {
  return NOTIFICATIONS_ICONS[action]
}

export const renderNotificationBasedOnAction = ({
  data,
  t,
}: {
  data: Notification
  t: TFunction
}) => {
  const formatSubstitute = () => {
    const newAssignee = path(['snapshot', 'account', 0, 'fullName'])(data)

    if (newAssignee) {
      return `${t('commentLogs.hasBeenSubstitutedWith')} ${newAssignee}`
    } else {
      return t('commentLogs.hasBeenRemoved')
    }
  }

  switch (true) {
    case NOTIFICATION_TYPES.COMMENT === data.type &&
      NOTIFICATION_ACTIONS.COMMENT_LIKE === data.action:
      return (
        <React.Fragment>
          {data.actor?.fullName ?? ''} {t('expressions.likedYourComment')}{' '}
          {data.comment?.task ? (
            <>
              {t('expressions.onTask')}{' '}
              <span className="font-semibold">
                {data?.comment?.task?.name ?? ''}
              </span>
            </>
          ) : data.comment?.thread ? (
            <>
              {t('expressions.onThread')}{' '}
              <span className="font-semibold">
                {data?.comment?.thread?.title ?? ''}
              </span>
            </>
          ) : (
            <>
              {t('expressions.onGoal')}{' '}
              <span className="font-semibold">
                {data?.comment?.goal?.name ?? ''}
              </span>
            </>
          )}{' '}
        </React.Fragment>
      )

    case NOTIFICATION_TYPES.COMMENT_REMINDER === data.type &&
      (NOTIFICATION_ACTIONS.COMMENT_REMINDER === data.action ||
        NOTIFICATION_ACTIONS.THREAD_COMMENT_REMINDER === data.action):
      return (
        <React.Fragment>
          {t('expressions.reminderForComment')}{' '}
          {formatDate(data.createdAt, true)}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.NEW_TASK === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.newTask')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
            70,
          )}"`}</span>{' '}
          {t('expressions.hasBeenAssignedToYou')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.DELETED_TASK === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.task')}{' '}
          <span className="font-semibold">{`"${parseText(
            (data.snapshot as NotificationValueTaskDeleted)?.taskName,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenDeleted')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.NEW_GOAL === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(
            `commentLogs.${data.goal?.boards?.length ? 'newGoal' : 'newToDo'}`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
            70,
          )}"`}</span>{' '}
          {t('expressions.hasBeenAssignedToYou')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.DELETED_GOAL === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${data.goal?.boards?.length ? 'goal' : 'toDo'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            (data.snapshot as NotificationValueGoalDeleted).value,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenDeleted')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.OWNER_CHANGED === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.theOwnerOfTask')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
          )}"`}</span>{' '}
          {formatSubstitute()}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.INPROGRESS_TASK === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.task')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenReturnedToStatusInProgress')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.INPROGRESS_GOAL === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${data.goal?.boards?.length ? 'goal' : 'toDo'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenReturnedToStatusInProgress')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.NEW_COMMENT === data.action &&
      NOTIFICATION_TYPES.COMMENT === data.type:
      return (
        <React.Fragment>
          {data.actor?.fullName ?? ''}{' '}
          {t(
            `expressions.${
              data.comment?.goal?.boards?.length
                ? 'commentedOnGoal'
                : data.comment?.task
                ? 'commentedOnTask'
                : 'commentedOnToDo'
            }`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.comment?.task?.name ?? data.comment?.goal?.name,
            70,
          )}"`}</span>{' '}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT === data.action &&
      NOTIFICATION_TYPES.COMMENT === data.type:
      return (
        <React.Fragment>
          {data.actor?.fullName ?? ''} {t('expressions.commentedOnThread')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.comment?.thread?.title,
            70,
          )}"`}</span>{' '}
        </React.Fragment>
      )
    case NOTIFICATION_ACTIONS.THREAD_NEW_COMMENT === data.action &&
      NOTIFICATION_TYPES.COMMENT_REPLY === data.type:
      return (
        <React.Fragment>
          {data.actor?.fullName ?? ''}{' '}
          {t('expressions.repliedToYourCommentOnThread')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.comment?.thread?.title,
            70,
          )}"`}</span>
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.NEW_COMMENT === data.action &&
      NOTIFICATION_TYPES.COMMENT_REPLY === data.type:
      return (
        <React.Fragment>
          {data.actor?.fullName ?? ''}{' '}
          {t(
            `expressions.${
              data.comment?.goal?.boards?.length
                ? 'repliedToYourCommentOnGoal'
                : data.comment?.task
                ? 'repliedToYourCommentOnTask'
                : 'repliedToYourCommentOnToDo'
            }`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.comment?.task?.name ?? data.comment?.goal?.name,
            70,
          )}"`}</span>
        </React.Fragment>
      )
    case NOTIFICATION_ACTIONS.DEADLINE_CHANGED === data.action &&
      NOTIFICATION_TYPES.TASK === data.type &&
      data.previousValue !== null:
      return (
        <React.Fragment>
          {t('commentLogs.deadLineOfTask')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenChangedTo')}{' '}
          {formatDate(data.snapshot as TimeStamp, true)} (
          {t('commentLogs.previousDeadline')}:{' '}
          {formatDate(data.previousValue as TimeStamp, true)})
        </React.Fragment>
      )

    case (NOTIFICATION_ACTIONS.MILESTONE_DEADLINE_CHANGED === data.action ||
      NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_DEADLINE_CHANGED ===
        data.action ||
      NOTIFICATION_ACTIONS.KEY_ACTIVITIES_DEADLINE_CHANGED === data.action) &&
      NOTIFICATION_TYPES.MILESTONE === data.type:
      return (
        <React.Fragment>
          {t(getActivityDeadlineTextKey(data.action))}{' '}
          <span className="font-semibold">{`"${parseText(
            data.milestone?.name,
          )}"`}</span>{' '}
          {isValidDate(data.milestone?.targetDeadline)
            ? `${t('commentLogs.hasBeenChangedTo')} ${formatDate(
                data.milestone?.targetDeadline,
              )}`
            : `${t('commentLogs.hasBeenRemoved')}`}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED ===
      data.action ||
      (NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED === data.action &&
        NOTIFICATION_TYPES.MILESTONE === data.type):
      return (
        <React.Fragment>
          {t(getActivityReminderTextKey(data.action))}{' '}
          <span className="font-semibold">{`"${parseText(
            data.milestone?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenSetTo')}{' '}
          {formatDate(data.milestone?.dynamicPlanner)}
        </React.Fragment>
      )

    case (NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_REMINDER_CHANGED ===
      data.action ||
      NOTIFICATION_ACTIONS.KEY_ACTIVITIES_REMINDER_CHANGED == data.type) &&
      NOTIFICATION_TYPES.MILESTONE === data.type &&
      !isValidDate(data.milestone?.dynamicPlanner):
      return (
        <React.Fragment>
          {t(getActivityReminderTextKey(data.action))}{' '}
          <span className="font-semibold">{`"${parseText(
            data.milestone?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenRemoved')}
        </React.Fragment>
      )

    case (NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED ===
      data.action ||
      NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED ===
        data.action) &&
      NOTIFICATION_TYPES.MILESTONE === data.type:
      return (
        <React.Fragment>
          {t(getActivityExecDateTextKey(data.action))}{' '}
          <span className="font-semibold">{`"${parseText(
            data.milestone?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenChangedTo')}{' '}
          {formatDate(data.milestone.executionDate)}
        </React.Fragment>
      )

    case (NOTIFICATION_ACTIONS.PRODUCTION_PLANNING_EXECUTION_DATE_CHANGED ===
      data.action ||
      NOTIFICATION_ACTIONS.KEY_ACTIVITIES_EXECUTION_DATE_CHANGED ===
        data.action) &&
      NOTIFICATION_TYPES.MILESTONE === data.type &&
      !isValidDate(data.milestone?.executionDate):
      return (
        <React.Fragment>
          {t(getActivityExecDateTextKey(data.action))}{' '}
          <span className="font-semibold">{`"${parseText(
            data.milestone?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenRemoved')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.PROJECT_DEADLINE_CHANGED === data.action &&
      NOTIFICATION_TYPES.PROJECT === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.deadLineOfProject')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.project?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenChangedTo')}{' '}
          {formatDate(data.project?.deadline)}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.GOAL_DEADLINE_CHANGED === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(
            `commentLogs.${
              data.goal?.boards?.length ? 'deadlineOfGoal' : 'deadlineOfToDo'
            }`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeenChangedTo')} {formatDate(data.goal?.deadline)}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.PENDING_DEADLINE_CHANGE_REQUEST === data.action &&
      NOTIFICATION_TYPES.DEADLINE_REQUEST === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.deadlineChangeRequestedOnTask')}{' '}
          <span className="font-semibold">
            {`"${data?.comment?.task?.name}"`}
          </span>
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.ACCEPTED_DEADLINE_CHANGE_REQUEST ===
      data.action && NOTIFICATION_TYPES.DEADLINE_REQUEST === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.yourDeadlineChangeRequestOnTask')}{' '}
          <span className="font-semibold">
            {`"${data?.comment?.task?.name}"`}
          </span>{' '}
          {t('commentLogs.hasBeenAccepted')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.REJECTED_DEADLINE_CHANGE_REQUEST ===
      data.action && NOTIFICATION_TYPES.DEADLINE_REQUEST === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.yourDeadlineChangeRequestOnTask')}{' '}
          <span className="font-semibold">
            {`"${data?.comment?.task?.name}"`}
          </span>{' '}
          {t('commentLogs.hasBeenRejected')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.CUSTOM_DEADLINE_CHANGE_REQUEST === data.action &&
      NOTIFICATION_TYPES.DEADLINE_REQUEST === data.type:
      return (
        <React.Fragment>
          {t('commentLogs.theOwnerOfTask')}{' '}
          <span className="font-semibold">
            {`"${data?.comment?.task?.name}"`}
          </span>{' '}
          {t('commentLogs.hasSetNewDeadline')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.DONE_TASK === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${isSubTask(data.task) ? 'subTask' : 'task'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeen')} {t('commentLogs.done')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.CANCELED_TASK === data.action &&
      NOTIFICATION_TYPES.TASK === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${isSubTask(data.task) ? 'subTask' : 'task'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeen')} {t('commentLogs.cancelled')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.DONE_GOAL === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${data.goal?.boards?.length ? 'goal' : 'toDo'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeen')} {t('commentLogs.done')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.CANCELED_GOAL === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(`commentLogs.${data.goal?.boards?.length ? 'goal' : 'toDo'}`)}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
            70,
          )}"`}</span>{' '}
          {t('commentLogs.hasBeen')} {t('commentLogs.cancelled')}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED === data.action &&
      NOTIFICATION_TYPES.TASK === data.type: {
      const newAssignee = path(['snapshot', 'account', 0, 'fullName'])(data)
      return (
        <React.Fragment>
          {t('commentLogs.theAssigneeOfTask')}{' '}
          <span className="font-semibold">{`"${parseText(
            data.task?.name,
          )}"`}</span>{' '}
          {formatSubstitute()}
          {newAssignee}
        </React.Fragment>
      )
    }

    case NOTIFICATION_ACTIONS.ASSIGNEE_CHANGED === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(
            `commentLogs.${
              data.goal?.boards?.length
                ? 'theAssigneeOfGoal'
                : 'theAssigneeOfToDo'
            }`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
          )}"`}</span>{' '}
          {formatSubstitute()}
        </React.Fragment>
      )

    case NOTIFICATION_ACTIONS.OWNER_CHANGED === data.action &&
      NOTIFICATION_TYPES.GOAL === data.type:
      return (
        <React.Fragment>
          {t(
            `commentLogs.${
              data.goal?.boards?.length ? 'theOwnerOfGoal' : 'theOwnerOfToDo'
            }`,
          )}{' '}
          <span className="font-semibold">{`"${parseText(
            data.goal?.name,
          )}"`}</span>{' '}
          {formatSubstitute()}
        </React.Fragment>
      )

    default:
      return ''
  }
}
