export const ADMIN_PANEL_PATHS = {
  USERS: 'users',
  INVITATIONS: 'invitations',
  CLIENTS: 'clients',
  PRODUCERS: 'producers',
  WORKING_DAYS: 'working-days',
  JOB_TITLES: 'job-titles',
  PROJECT_TYPES: 'project-types',
  TIME_FACTORS: 'time-factors',
  MILESTONES: 'base-milestones',
  PROCEDURES: 'procedures',
  SIZE_STANDARDS: 'size-standards',
  TEAMS: 'teams',
  DOCUMENT_CATEGORIES: 'document-categories',
  LABELS: 'labels',
  APPLICATION_SETTINGS: 'application-settings',
}
