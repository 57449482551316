import React, { MouseEventHandler, useState } from 'react'
import { Popover as HeadlessPopover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import { defaultModifiers } from '@utils/popper'
import type { Modifier, Placement, PositioningStrategy } from '@popperjs/core'

export interface IPopoverProps {
  buttonRef?: React.MutableRefObject<any>
  button:
    | React.ReactNode
    | (({ open, close }: { open: boolean; close: Function }) => React.ReactNode)
  content:
    | React.ReactNode
    | (({
        updatePopper,
        close,
      }: {
        updatePopper: Function
        close: Function
      }) => React.ReactNode)
  popperPlacement?: Placement
  popperStrategy?: PositioningStrategy
  popperModifiers?: Partial<Modifier<unknown, object>>[]
  disabled?: boolean
  panelClassName?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Popover: React.FunctionComponent<IPopoverProps> = ({
  buttonRef,
  button,
  content,
  popperPlacement,
  popperStrategy,
  popperModifiers,
  disabled,
  panelClassName,
  onClick,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>()
  const [popperElement, setPopperElement] = useState<HTMLElement>()
  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement,
    strategy: popperStrategy,
    modifiers: [...defaultModifiers, ...(popperModifiers || [])],
  })

  return (
    <HeadlessPopover onClick={onClick}>
      {({ open, close }) => (
        <>
          <HeadlessPopover.Button
            ref={(ref) => {
              setReferenceElement(ref)
              if (buttonRef?.current) {
                buttonRef.current = ref
              }
            }}
            as="div"
            disabled={disabled}
          >
            {typeof button === 'function' ? button({ open, close }) : button}
          </HeadlessPopover.Button>
          <HeadlessPopover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={classNames(['z-20', panelClassName])}
          >
            {typeof content === 'function'
              ? content({ updatePopper, close })
              : content}
          </HeadlessPopover.Panel>
        </>
      )}
    </HeadlessPopover>
  )
}

export default Popover
