import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@ag-grid-community/styles/ag-grid.css'
import '@assets/ag-custom-theme-hl.css'
import '@assets/main.css'
import 'assets/trix-custom.css'
import 'assets/syncfusion.css'
import '@assets/components.css'
import AppMain from '@components/App/AppMain'

function App(props) {
  return <AppMain {...props} />
}

export default App
